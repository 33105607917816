import styled from 'styled-components';

export const StyledLoader = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 400px;
    align-items: center;
    justify-content: center;
`;
