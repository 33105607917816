import React, {useCallback, useEffect} from 'react';
import dynamic from 'next/dynamic';
import {useRouter} from 'next/dist/client/router';

import {PageDataPropsType} from '~api/fetchPagesData';
import {PageQueryPageDataAttributesContent} from '~interfaces/graphql/PageQuery';
import {useModalContent} from '~providers/StakingModalContentProvider';
import Loader from '~atoms/Loader';

const GenesisNFT = dynamic(() => import('~components/pages/GenesisNFT'));
const NFTThankYouPage = dynamic(() => import('~pages/NFTThankYou'));

const TokenInfo = dynamic(() => import('~molecules/TokenInfo'));
const Partners = dynamic(() => import('~molecules/Partners'));
const ChamberSection = dynamic(() => import('~organisms/Homepage/ChamberSection'));
const DomeSection = dynamic(() => import('~organisms/Homepage/DomeSection'));
const TheGatewaySection = dynamic(
    () => import('~organisms/Homepage/TheGatewaySection'),
);
const Roadmap = dynamic(() => import('~components/organisms/Homepage/Roadmap'));
const HeroVideo = dynamic(() => import('~molecules/HeroVideo'));
const Team = dynamic(() => import('~organisms/Team'));
const DomeEstateSection = dynamic(
    () => import('~organisms/Homepage/DomeEstateSection'),
);
const TitleSection = dynamic(() => import('~organisms/Homepage/TitleSection'));
const RichTextPage = dynamic(() => import('~pages/RichTextPage'));
const Tokenomics = dynamic(() => import('~organisms/Homepage/Tokenomics'));
const DomeCommunity = dynamic(() => import('~organisms/Homepage/DomeCommunity'));
const TokenPresale = dynamic(() => import('~organisms/TokenPresale'), {
    loading: () => <Loader />,
});

const StakePage = dynamic(() => import('~organisms/Staking'), {
    loading: () => <Loader />,
});
const LeaderboardPage = dynamic(() => import('~pages/LeaderboardPage'), {
    loading: () => <Loader />,
});
const CountdownPage = dynamic(() => import('~pages/CountdownPage'), {
    loading: () => <Loader />,
});
const ContactFormSection = dynamic(
    () => import('~components/organisms/Homepage/ContactFormSection'),
);

const PartnersSlider = dynamic(() => import('~components/molecules/PartnersSlider'));

type SectionsProps = Pick<PageDataPropsType, 'sections'>;

enum SectionsTypename {
    TitleSection = 'ComponentSectionTitleSection',
    DomeSection = 'ComponentSectionDome',
    HeroVideo = 'ComponentSectionHeroVideo',
    ChamberSection = 'ComponentSectionChamber',
    Team = 'ComponentSectionTeam',
    TheGatewaySection = 'ComponentSectionTheGateway',
    DomeEstateSection = 'ComponentSectionDomeEstate',
    Roadmap = 'ComponentSectionRoadmap',
    RichTextPage = 'ComponentSectionRichTextPage',
    Tokenomics = 'ComponentSectionTokenomics',
    DomeCommunity = 'ComponentSectionDomeCommunityBlocks',
    TokenPresale = 'ComponentSectionTokenPresale',
    TokenInfo = 'ComponentAtomsTokenInfo',
    Partners = 'ComponentSectionPartners',
    StakingPopup = 'ComponentAtomsStakingPopup',
    StakePage = 'ComponentSectionStakePage',
    LeaderboardPage = 'ComponentPagesLeaderboardPage',
    CountdownPage = 'ComponentPagesTimerPage',
    ContactForm = 'ComponentSectionContactForm',
    NFTPage = 'ComponentPagesNftPage',
    NFTThankYouPage = 'ComponentPagesNftThankYou',
    PartnersSlider = 'ComponentSectionPartnersSlider',
}

const Sections = ({sections}: SectionsProps) => {
    const {setContent} = useModalContent();
    const router = useRouter();

    useEffect(() => {
        sections?.forEach((section) => {
            if (section.__typename === SectionsTypename.StakingPopup) {
                setContent(section);
            }
        });
    }, [sections]);

    const renderComponent = useCallback(
        (section: PageQueryPageDataAttributesContent, index: number) => {
            switch (section.__typename) {
                case SectionsTypename.TitleSection: {
                    return <TitleSection key={index} {...section} />;
                }
                case SectionsTypename.HeroVideo: {
                    return <HeroVideo key={index} {...section} />;
                }
                case SectionsTypename.Team: {
                    return <Team key={index} {...section} />;
                }
                case SectionsTypename.DomeSection: {
                    return <DomeSection data={section} key={index} />;
                }
                case SectionsTypename.TheGatewaySection: {
                    return <TheGatewaySection data={section} key={index} />;
                }
                case SectionsTypename.Roadmap: {
                    return <Roadmap data={section} key={index} />;
                }
                case SectionsTypename.ChamberSection: {
                    return <ChamberSection key={index} {...section} />;
                }
                case SectionsTypename.DomeEstateSection: {
                    return <DomeEstateSection key={index} {...section} />;
                }
                case SectionsTypename.RichTextPage: {
                    return <RichTextPage key={index} {...section} />;
                }
                case SectionsTypename.Tokenomics: {
                    return <Tokenomics key={index} {...section} />;
                }
                case SectionsTypename.DomeCommunity: {
                    return <DomeCommunity key={index} {...section} />;
                }
                case SectionsTypename.TokenPresale: {
                    return <TokenPresale key={router.asPath} {...section} />;
                }
                case SectionsTypename.TokenInfo: {
                    return <TokenInfo key={index} {...section} />;
                }
                case SectionsTypename.Partners: {
                    return <Partners key={index} {...section} />;
                }
                case SectionsTypename.StakePage: {
                    return <StakePage key={index} {...section} />;
                }
                case SectionsTypename.LeaderboardPage: {
                    return <LeaderboardPage key={index} {...section} />;
                }
                case SectionsTypename.CountdownPage: {
                    return <CountdownPage key={index} {...section} />;
                }
                case SectionsTypename.ContactForm: {
                    return <ContactFormSection key={index} {...section} />;
                }
                case SectionsTypename.NFTPage: {
                    return <GenesisNFT key={index} {...section} />;
                }
                case SectionsTypename.NFTThankYouPage: {
                    return <NFTThankYouPage key={index} {...section} />;
                }
                case SectionsTypename.PartnersSlider: {
                    return <PartnersSlider key={index} {...section} />;
                }
                default:
                    return null;
            }
        },
        [router],
    );

    return <>{(sections || []).map(renderComponent)}</>;
};
export default Sections;
