import React from 'react';
import {RingLoader} from 'react-spinners';

import {StyledLoader} from './styles';

const Loader = () => (
    <StyledLoader>
        <RingLoader color="#EE4492" />
    </StyledLoader>
);

export default Loader;
